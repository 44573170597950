
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";
import Chart from "chart.js/auto";

import CSpinner from "@/components/Spinner.vue";
import CCashierCard from "@/views/cashier/Card.vue";
import CCashierDetailCard from "@/views/cashier/DetailCard.vue";

import {
  addMoneyMask,
  formatDate,
  formatDateMMAAAA,
  formatHours,
  formatPhone,
  formatCPFToLGPD,
  compareDates,
} from "@/libs/utils";
import { Guide } from "@/store/guide/types";

const VStatus = defineComponent({
  name: "VStatus",
  components: { CSpinner, CCashierCard, CCashierDetailCard },
  setup() {
    useHead({ title: "Meu caixa | obmed" });

    const store = useStore();
    const route = useRoute();
    const monthType = computed(() => (route.params.type === "atual" ? "current" : "last"));

    const guides = computed(() => store.state.guide.list);

    function groupGuidesByPatient(guides: Guide[]) {
      const handledGuides: Guide[] = [];
      const patientCPFs: string[] = [];

      guides.forEach((item) => {
        if (item.nr_cpf_paciente && !patientCPFs.includes(item.nr_cpf_paciente)) {
          handledGuides.push(item);
          patientCPFs.push(item.nr_cpf_paciente);
        }
      });

      return handledGuides.map((item) => ({
        ...item,
        items: guides.filter((subitem) => subitem.nr_cpf_paciente === item.nr_cpf_paciente),
      }));
    }

    const currentGuides = computed(() =>
      guides.value
        .filter((item) => item.nm_situacao.toLowerCase() === "executada")
        .filter((guide) => compareDates(guide.dt_agenda, new Date().toISOString().split("T")[0]))
    );
    const pastGuides = computed(() =>
      guides.value
        .filter((item) => item.nm_situacao.toLowerCase() === "executada")
        .filter((guide) => !compareDates(guide.dt_agenda, new Date().toISOString().split("T")[0]))
    );

    const currentGroupedGuides = computed(() => groupGuidesByPatient(currentGuides.value));
    const pastGroupedGuides = computed(() => groupGuidesByPatient(pastGuides.value));

    const currentValueGuides = computed(() =>
      currentGuides.value.reduce((total, guide) => total + guide.valor_repasse, 0)
    );
    const pastValueGuides = computed(() => pastGuides.value.reduce((total, guide) => total + guide.valor_repasse, 0));
    const possibleValueGuides = computed(() =>
      guides.value
        .filter((item) => item.nm_situacao.toLowerCase() === "emitida")
        .reduce((total, guide) => total + guide.valor_repasse, 0)
    );

    const cashiers = computed(() =>
      monthType.value === "current" ? currentGroupedGuides.value : pastGroupedGuides.value
    );
    const dateInfo = computed(() =>
      formatDateMMAAAA(
        monthType.value === "current"
          ? new Date().toISOString()
          : new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString()
      )
    );

    const loading = reactive({ list: false });
    const chart = ref<any | null>(null);

    function createChart({ context, data }: any) {
      chart.value = new Chart(context, { type: "doughnut", data });
    }

    async function getGuides() {
      loading.list = true;
      await store.dispatch("getGuides");
      loading.list = false;

      createChart({
        context: "status-value-current",
        data: {
          labels: ["Executado", "Pendente"],
          datasets: [
            {
              label: "Valores",
              data: [currentValueGuides.value, possibleValueGuides.value],
              backgroundColor: ["#42b19e", "#faad14"],
            },
          ],
        },
      });
      createChart({
        context: "status-value-last",
        data: {
          labels: ["Recebido"],
          datasets: [
            {
              label: "Recebido",
              data: [pastValueGuides.value],
              backgroundColor: ["#40afe2"],
            },
          ],
        },
      });
    }

    getGuides();

    return {
      ...{ guides, cashiers, monthType, dateInfo, loading },
      ...{ pastGroupedGuides, currentGroupedGuides, pastValueGuides, currentValueGuides, possibleValueGuides },
      ...{ formatPhone, formatDate, formatHours, formatDateMMAAAA, addMoneyMask, formatCPFToLGPD },
    };
  },
});

export default VStatus;
