
import { computed, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useRouterUtils } from "@/libs/utils";

const CAppointmentCard = defineComponent({
  name: "CAppointmentCard",
  props: {
    itemID: { type: Number, required: true },
    patientName: { type: String, default: "paciente" },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const { normalizeRouteParam } = useRouterUtils();

    const hash = computed(() => `${normalizeRouteParam(props.patientName)}-${props.itemID}`);
    const showDetails = computed(() => route.hash === `#${hash.value}`);

    function handleDetailClick() {
      if (showDetails.value) return router.replace({ hash: "" });
      router.replace({ hash: `#${hash.value}` });
    }

    return { showDetails, hash, handleDetailClick };
  },
});

export default CAppointmentCard;
